import Layout from "../components/layout"
import React from "react"
import SEO from "../components/seo"
import imgHead from "../images/mode-emploi-shiftyourjob.svg"

function GuideUtilisationPage(props: {}) {
  return (
    <Layout contentClassName="container">
      <SEO
        title="Guide d'utilisation et précautions pour un usage éclairé de Shift Your Job"
        description="Utilisez la base pour vous inspirer, nourrir votre démarche personnelle et trouver des idées d'entreprises et d'organisations où postuler, mais faites toujours preuve d’esprit critique. Il ne faut en aucun cas voir la base comme une “liste de bons élèves”. Nous vous invitons donc à toujours faire preuve d’esprit critique et à mener vos propres recherches complémentaires. Découvez les limites que nous avons identifées sur l'approche"
        gtmPageType="guide-utilisation"
        gtmPageName="guide-utilisation"
      />

      <div className="relative mx-2 pt-12 md:mx-auto md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl -mb-10 md:pb-16 z-10">
        <h1 className="text-3xl xl:text-5xl text-primary font-bold mb-12 leading-tight">
          Guide&nbsp;d'utilisation
          <br />
          et&nbsp;précautions
        </h1>
        <p className="w-11/12 md:w-64">
          La cartographie peut avoir différents usages pour quiconque
          s’intéresse à l’enjeu du climat. Son objectif premier est d’aider
          celles et ceux qui cherchent un emploi, à identifier des structures où
          postuler.
        </p>
      </div>

      <img
        className="illustration md:hidden mx-auto"
        src={imgHead}
        alt=""
        aria-hidden="true"
      />
      <div className="headline bg-primary text-white md:mt-16 text-center">
        <div className="relative container max-w-6xl mx-auto">
          <img
            className="illustration right-0 z-0 hidden md:block"
            src={imgHead}
            alt=""
            aria-hidden="true"
          />
        </div>

        <h2 className="font-serif text-xl max-w-4xl mt-4 italic mx-auto font-normal">
          “Utilisez la base pour vous inspirer, nourrir votre démarche
          personnelle et trouver des idées d'entreprises et d'organisations où
          postuler, mais faites toujours preuve d’esprit critique.”
        </h2>
      </div>
      <div className="relative mb-8 z-10 -mt-6">
        <div
          className="absolute bg-secondary top-0 left-1/2 "
          style={{ width: "4px", height: "64px" }}
        ></div>
      </div>
      <article className="markdown mt-16 pt-6 md:mx-auto md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl ">
        <div className="px-4 container md:mx-auto max-w-3xl ">
          <p>
            Consultez la cartographie pour mieux comprendre l’architecture
            globale des acteurs du climat en France ou encore vous inspirer pour
            trouver des idées de création d’entreprise ou d’association.
            <br />
            <br />
            Naviguez directement dans l’arborescence sectorielle ou recherchez
            par mot clef, par localisation, ou par secteur, et appliquez des
            filtres.
          </p>
          <h2>
            Une ressource à utiliser généreusement...
            <br />
            tout en faisant preuve d’esprit critique
          </h2>
          <h3>
            Il ne faut en aucun cas voir la base comme une “liste de bons
            élèves”:
          </h3>
          <ul>
            <li>
              L'efficacité réelle de certaines solutions de substitution ou
              d’optimisation fait toujours débat. Nous avons choisi d’intégrer
              toutes celles qui avaient démontré une certaine pertinence tout en
              précisant les limites identifiées.{" "}
            </li>
            <li>
              La liste n’est pas exhaustive, et ne le sera jamais, malgré tous
              nos efforts pour la compléter.{" "}
            </li>
            <li>
              Nous n’avons pas audité ces organisations, notre sélection est
              basée sur l’information publiquement disponible.
            </li>
            <li>
              Certaines organisations ont plusieurs activités dont seulement une
              partie contribue à la transition. Nous avons choisi de les garder
              quand elles étaient significatives, et quand les autres activités
              n’étaient pas directement néfastes pour le climat.{" "}
            </li>
            <li>
              Nous n’avons pas pu évaluer les aspects indépendants de l’enjeu
              climatique et qui peuvent être cruciaux : responsabilité sociétale
              et environnementale, gouvernance, santé financière.
            </li>
          </ul>
          <h3>
            Nous vous invitons donc à toujours faire preuve d’esprit critique et
            à mener vos propres recherches complémentaires :
          </h3>
          <ul>
            <li>
              La lecture de l'éditorial (page de présentation des secteurs) est
              essentielle à l’utilisation de la base, car celui-ci donne les clés de
              lecture pour comprendre les enjeux et les limites identifiées,
              ainsi que les critères que nous avons employés pour chaque secteur
              et sous-secteur. Utilisez-le pour forger votre propre avis sur les
              organisations.
            </li>
            <li>
              Ne nous croyez pas sur parole ! Quand vous identifiez une entité
              intéressante, menez vos propres recherches pour vous assurer
              qu’elle est alignée avec vos attentes. Si lors de cette étape vous
              rencontrez des incohérences avec les informations de notre base de
              données, c’est le moment de nous le signaler !{" "}
            </li>
          </ul>
          <p>
            Utilisez la base pour vous inspirer, nourrir votre démarche
            personnelle et trouver des idées d'entreprises ou d'organisations où
            postuler, mais faites toujours preuve d’esprit critique.{" "}
          </p>
          <h2>Autres limites de l’exercice</h2>
          <h3>
            Un focus “climat” - laissant de côté les autres{" "}
            <a
              href="https://fr.wikipedia.org/wiki/Limites_plan%C3%A9taires"
              target="_blank"
            >
              limites planétaires.
            </a>
          </h3>
          <p>
            Nous sommes conscient·e·s des autres enjeux sociaux et
            environnementaux de la transition au sens large. Face à l’ampleur de
            la tâche, nous avons choisi de nous concentrer sur l’enjeu climat en
            cohérence avec l’ambition et la mission sur Shift Project.
          </p>
          <h3>Une absence de hiérarchisation dans l'impact.</h3>
          <p>
            L’impact des différentes organisations de la cartographie n’est pas
            homogène, mais il nous est impossible aujourd’hui d’en donner une
            mesure quantitative rigoureuse, c’est pourquoi nous n’avons pas
            souhaité les hiérarchiser. La lecture de l’éditorial vous donnera
            toutefois quelques éléments sur le potentiel d’impact des différents
            secteurs ou solutions.{" "}
          </p>

          <div className="px-6 md:px-12 py-8 container font-sm leading-7 mx-auto text-left max-w-3xl border-secondary border-4 mt-8">
            <h3>
              Quelques ressources complémentaires pour évaluer l’impact
              potentiel d’une activité :{" "}
            </h3>
            <ul>
              <li>
                <a
                  href="https://ilnousfautunplan.fr"
                  target="_blank"
                  className="underline"
                >
                  le "Plan de Transformation de l'Economie Française"
                </a>{" "}
                - du Shift Project
              </li>
              <li>
                <a
                  href="https://pour-un-reveil-ecologique.org/fr/choisir-son-entreprise/"
                  target="_blank"
                  className="underline"
                >
                  Le guide de "Pour un Réveil écologique"
                </a>
                , pour aiguiser votre sens critique et vous protéger du
                greenwashing{" "}
              </li>
            </ul>
          </div>
          <h3>
            Un exercice qui s’inscrit dans le "système" actuel avec ses limites.
          </h3>
          <ul>
            <li>
              Plusieurs des organisations sont ancrées dans le système même qui
              est à l’origine du dérèglement climatique. Les intégrer n’est pas
              une prise de position contre la nécessité d’un changement plus
              radical. Simplement, nous nous limitons dans notre étude à une
              approche “technique”, à l’échelle de la solution, sans envisager
              de transformations systémiques plus globales, que nous ne sommes
              pas en capacité de modéliser.
            </li>
            <li>
              En particulier, de nombreuses activités entraînent des effets
              rebonds. Notre ambition est de les signaler autant que possible
              dans l’éditorial.
            </li>
            <li>
              En raison de leurs autres activités fortement émettrices de gaz à
              effet de serre, nous avons volontairement écarté un certain nombre
              de grands acteurs de l’industrie pétrolière ou de la gestion
              d’actifs qui, par leur positionnement et leur taille, pourraient
              être selon d'autres des acteurs majeurs de la transition. Nous
              avons adopté une approche "in or out" proche de celle de la
              taxonomie européenne, parce que nous n’avons pas les moyens de
              juger de l’ambition, des efforts et des résultats de ces très gros
              acteurs qui peuvent avoir un rôle très structurant sur nos
              économies. Nous sommes pour autant parfaitement conscients qu'il
              existe au sein de ces acteurs des marges de manœuvre très
              importantes voire décisives dans la transition. Nous n'excluons
              d'ailleurs pas à terme de pouvoir intégrer dans notre cartographie
              des acteurs qui à défaut d'être encore "bas carbone" sont bien "en
              transition vers". D'ores et déjà, nous avons en revanche listé
              quand cela était possible leurs filiales dédiées à des activités
              en lien avec la transition.
            </li>
            <li>
              De même, certaines entreprises ont pleinement intégré l'enjeu
              climatique et ont fait des efforts considérables pour revoir
              structurellement leur modèle économique afin de le rendre
              compatible avec une trajectoire 2 degrés, d'autres les suivent et
              s'engagent sur cette voie. Nous espérons pouvoir les intégrer dans
              une prochaine évolution du site, une fois que nous aurons
              déterminé sur quels critères de sélection robustes nous pourrions
              nous appuyer, afin de dépasser les discours marketing.
            </li>
            <h3>...Et qui n’intègre pas la culture d’entreprise.</h3>
            <p>
              {" "}
              Faute de données fiables et exploitables objectivement, nous ne
              pouvons prendre en compte la culture d’entreprise, le style de
              management, les valeurs, l’ambiance etc. dans notre sélection.
              Nous invitons donc chacun·e à se renseigner auprès de
              collaborateurs·trices actuel·le·s et ancien·ne·s, par exemple via
              LinkedIn, et/ou à se rendre sur des sites de type Glassdoor pour
              avoir des avis informés sur l’entreprise.
            </p>
          </ul>
        </div>
      </article>
    </Layout>
  )
}

export default GuideUtilisationPage
