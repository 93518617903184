import React, { useEffect } from "react"
import { transform } from "typescript"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Lever, allLeverType } from "../utils/model"
import classnames from "classnames"
import imgHead from "../images/philosophie-shiftyourjob.svg"

type PhilosophiePageProps = {
  data: {
    levers: allLeverType
  }
}

function PhilosophiePage(props: PhilosophiePageProps) {
  useEffect(() => {
    document.body.className = "page-philo"
  }, [])

  const levers = props.data?.levers.nodes
    ? props.data?.levers.nodes
        .map(l => {
          let pictoURL = null
          if (l.data.LeverPictoURL) {
            pictoURL = l.data.LeverPictoURL
          }
          // return new Lever(l.id, l.data.Name, l.data.Label?.childMarkdownRemark.html, pictoURL, l.data.Featured)
          return new Lever(
            l.id,
            l.data.Name,
            l.data.Label?.childMarkdownRemark.html,
            // l.data.Label,
            pictoURL,
            l.data.Featured,
            l.data.Prio
          )
        })
        .sort((a, b) => {
          const nameA = a.prio
          const nameB = b.prio
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }

          return 0
        })
    : []

  const title =
    "Le projet Shift Your Job: travailler au service de la lutte contre le dérèglement climatique"

  return (
    <Layout contentClassName="container">
      <SEO
        title={title}
        description=" "
        gtmPageType="philosophie"
        gtmPageName="philosophie"
      />

      <div className="relative mx-2 pt-12 md:mx-auto md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl -mb-10 md:pb-16 z-10">
        <h1 className="text-3xl xl:text-5xl text-primary font-bold mb-12">
          Philosophie
        </h1>
        <p className="w-11/12 md:w-64">
          En tant que bénévoles du{" "}
          <a
            href="https://theshiftproject.org/"
            className="custom-underline"
            target="_blank"
          >
            Shift&nbsp;Project
          </a>
          , nous sommes sensibilisé·e·s à l’impérieuse nécessité de réduire
          nos émissions pour faire face à l’urgence climatique.
        </p>
      </div>

      <img
        className="illustration md:hidden mx-auto"
        src={imgHead}
        alt=""
        aria-hidden="true"
      />
      <div className="headline bg-primary text-white md:mt-16 text-center">
        <div className="relative container max-w-6xl mx-auto">
          <img
            className="illustration right-0 z-0 hidden md:block"
            src={imgHead}
            alt=""
            aria-hidden="true"
          />
        </div>

        <h2 className="font-serif text-xl max-w-4xl mt-4 italic mx-auto font-normal">
          “Où travailler pour contribuer à la transition carbone
          <br />
          et avoir un impact sur le climat ?”
        </h2>
      </div>
      <div className="relative mb-8 z-10 -mt-6">
        <div
          className="absolute bg-secondary top-0 left-1/2 "
          style={{ width: "4px", height: "64px" }}
        ></div>
      </div>
      <article className="markdown pt-6 md:mx-auto md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl ">
        <div className="px-4 container md:mx-auto max-w-3xl ">
          <h3>En tant que Shifters, nous tentons d'agir à notre niveau :</h3>
          <ul>
            <li>
              dans notre vie privée, en faisant évoluer notre mode de vie, notre
              consommation ou encore notre épargne,
            </li>
            <li>
              dans notre vie citoyenne, en nous engageant avec une association
              ou un parti pour agir auprès de la société civile,
            </li>
            <li>
              dans notre vie professionnelle, en faisant changer notre
              entreprise ou notre organisation de l’intérieur ou en nous posant
              la question "Où et comment puis-je travailler pour contribuer à la
              transition vers une économie moins émettrice de gaz à effet de
              serre&nbsp;?”
            </li>
          </ul>
          <p>
            C’est pour aider chacun·e à répondre à cette question qu’une
            quarantaine de Shifters ont réalisé une cartographie des entreprises
            et organisations dont l’activité et la mission sont alignées avec
            les enjeux de la transition carbone. Son ambition est d’inspirer et
            orienter celles et ceux qui souhaitent aligner leur engagement
            professionnel avec la lutte contre le dérèglement climatique. La
            transition énergie-climat est un enjeu urgent, transverse et
            multiforme, qui a besoin de tous types de profils et de compétences.
            Notre souhait : que chacun·e puisse trouver sa place dans la
            transition, et contribuer au défi du siècle tout en trouvant du sens
            dans son travail.
          </p>
        </div>

        <div className="px-6 md:px-12 py-8 container font-sm leading-7 mx-auto text-left max-w-3xl border-secondary border-4 mt-8">
          Notre message n’est pas qu’il faut changer de travail pour contribuer
          à la transition. Nous pensons qu’il est nécessaire d’avoir des gens
          conscients et engagés dans tous les secteurs de l’économie, et que
          chacun·e peut agir à son niveau dans sa structure actuelle, y compris
          dans celles qui ne sont pas répertoriées ici. Mais la prise de
          conscience de l’urgence climatique peut aussi faire naître chez
          certain·e·s une envie de réorientation professionnelle, que notre
          cartographie se propose d’accompagner.
        </div>

        <div className="px-4 container md:mx-auto max-w-3xl">
          <h2>
            Grâce à une base de données structurée, contextualisée et
            collaborative
          </h2>
          <h3>
            Pour aiguiller chacun·e vers sa place dans la transition énergie
            climat, Shift Your Job c’est :
          </h3>

          <ol>
            <li>
              <h4>Une base de données</h4>
              <p>
                Plus de 2000 organisations sélectionnées pour leur rôle dans la
                transition énergie-climat : entreprises (parfois filiales ou
                business units), associations et institutions publiques. Pour
                chacune, nous avons rassemblé des informations structurantes :
                description de l’activité, type de structure, localisation,
                effectifs, date de création, levier d’impact, lien vers le site
                et la page Linkedin, principaux métiers et formations
                représentés. La plupart des informations viennent du site web de
                l'organisation et de leur page LinkedIn. Nous nous sommes
                concentré·e·s sur les acteurs présents en France, à l’exception
                de quelques initiatives à l’étranger sans équivalence française
                pouvant servir de sources d’inspiration.
              </p>
            </li>
            <li>
              <h4>Une arborescence</h4>
              <p>
                Elle s’articule autour de 12 secteurs d’activités et plus de 300
                sous-secteurs, pour structurer la base et faciliter son
                exploration.{" "}
              </p>
            </li>
            <li>
              <h4>De l’information qualitative</h4>
              <p>
                Des décryptages pour chacun des principaux secteurs et sous-secteurs
                de l’arborescence, qui donnent les clés de lecture nécessaires à
                la compréhension et l’utilisation de la base. On y trouve des
                chiffres-clés, des informations sur le contexte général, sur les
                émissions de gaz à effet de serre, sur l’interdépendance avec
                les autres secteurs, et sur les critères qui ont été employés
                pour sélectionner les organisations pour le secteur en question.
              </p>
            </li>
          </ol>

          <h2>
            Un travail collectif selon une méthodologie transparente qui
            s’appuie sur des rapports de référence
          </h2>
          <h3>Sélection des organisations</h3>

          <p>La base de données est alimentée selon deux modes :</p>
          <ul>
            <li>
              Lors de sa création en 2020, une équipe de bénévoles a rassemblé
              plus de 1000 organisations en se basant très majoritairement sur
              leur site Internet, leur page LinkedIn ou
              Wikipedia. Chaque organisation a fait l’objet d’une “peer review”
              (examen par des pairs) par au moins deux contributeurs au cours
              d’un protocole de validation.
            </li>
            <li>
              Shift Your Job est un projet collaboratif. La base de données a
              ainsi vocation à s’étoffer par vos suggestions de nouvelles
              entrées et vos propositions de mise à jour des informations
              qu'elle contient. Vos suggestions sont soumises au même protocole
              de validation que lors de la création de la base de données.
            </li>
          </ul>
          <p>
            La sélection s'est faite sur l'évaluation par les bénévoles de la
            compatibilité de l'activité de l'organisation avec une trajectoire
            crédible de transition limitant le réchauffement climatique en deçà
            de 2°C.
            <br />
            <br />
            Ont été considérés comme “trajectoires crédibles de transition” les
            scénarios défendus par des rapports généraux ou sectoriels faisant
            référence, et en particulier
            <a
              href="https://ilnousfautunplan.fr"
              target="_blank"
            >
              Le Plan de Transformation de L'Économie Française
            </a>{" "}
            et le
            <a href="https://drawdown.org" target="_blank">
              Drawdown Report.
            </a>
            <br />
            <br />
            Les données présentées sont issues de différents rapports, notamment
            de ces deux rapports de référence. Les chiffres et citations ont
            été systématiquement sourcées.
            <br />
          </p>
        </div>
      </article>
      <div className="pt-6 md:mx-auto md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl pb-16">
        <div className="px-4 container md:mx-auto max-w-3xl ">
          <Link
            to="/contribuer"
            className="no-underline font-sans font-bold text-sm bg-transparent rounded border-2 border-secondary text-primary text-center px-6 py-3 transition duration-300 ease transition-colors hover:bg-secondary hover:text-primary "
          >
            Ajouter ou modifier une organisation
          </Link>
        </div>
      </div>
      <div className="relative mb-8 z-10">
        <div
          className="absolute bg-secondary top-0 left-1/2 "
          style={{ width: "4px", height: "64px" }}
        ></div>
      </div>
      <div className="bg-primary text-white relative p-10 py-16 text-center">
        <div className="max-w-4xl mx-auto">
          <div className="font-sans font-bold text-3xl md:text-4xl mb-5">
            Caractérisation de l’impact
          </div>
          <p className="">
            Chaque organisation active au moins un des 6 leviers d’impact que
            nous avons identifiés pour décarboner l’économie en nous inspirant
            de l’équation de Kaya. Celle-ci relie les émissions de gaz à effet
            de serre anthropiques à des paramètres d'ordre démographique,
            économique et énergétique.
          </p>
          <div className="mt-5 font-sans font-bold text-secondary">
          <p>
            Le niveau total des émissions générées par l’Homme
            peut ainsi s'exprimer comme le produit de quatre facteurs : la
            population, le PIB par habitant, l’intensité énergétique et le
            contenu en CO2 de l’énergie consommée&nbsp;:
          </p>
            CO2 = Population x (PIB/Population) x (Energie/PIB) x (CO2/Énergie)
          </div>
        </div>
      </div>
      <div id="les-leviers" className="relative mb-8 z-10 -mt-6">
        <div
          className="absolute bg-secondary top-0 left-1/2 "
          style={{ width: "4px", height: "64px" }}
        ></div>
      </div>
      <div className="border-secondary border-4 relative p-10 mt-16 mb-20 text-center md:mx-auto md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl">
        <div className="my-4 max-w-2xl mx-auto">
          <h2 className="text-primary text-2xl md:text-4xl font-black mb-4 text-center">
            Les 6 leviers d’impact
          </h2>
          <ul className="my-6 text-left ">
            {levers.map(l => (
              <li
                key={l.id}
                className={classnames(
                  "flex items-center text-sm  my-3 pl-12 relative",
                  { hidden: !l.featured }
                )}
              >
                <span className="absolute top-0 left-0 w-10 mr-2">
                  <img
                    className="mx-auto"
                    style={{ marginTop: "-2px" }}
                    src={l.pictoURL}
                  />
                </span>
                <div className="text-left">
                  <h3 className="font-sans font-bold text-base text-primary ">
                    {l.name}
                  </h3>
                  <div
                    className="markdown"
                    dangerouslySetInnerHTML={{ __html: l.label }}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="bg-primary text-white relative p-10 py-16 text-center">
        <div className="max-w-4xl mx-auto">
          <div className="font-sans font-bold font-4xl text-white text-center mb-10">
            Pour en savoir plus sur le fonctionnement de la cartographie,
            découvrez le mode d’emploi de shiftyourjob.org
          </div>
          <Link
            to={"/guide-utilisation"}
            className="font-sans font-bold text-sm bg-secondary text-primary text-center rounded px-6 py-4 transition duration-300 ease transition-colors hover:bg-white"
          >
            Découvrir le mode d'emploi
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PhiloQuery {
    levers: allAirtable(filter: { table: { eq: "Lever" } }) {
      nodes {
        id
        data {
          Name
          Label {
            childMarkdownRemark {
              html
            }
          }
          LeverPictoURL
          Picto {
            localFiles {
              url
            }
          }
          Featured
          Prio
        }
      }
    }
  }
`

export default PhilosophiePage
